import { HtmlBlock } from '@/cms/definitions/content-types';
import Coverage from './components/coverageComponent/Coverage';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { isDevelop } from '@/views/calculators/services/environmentService';
import store from '@/store/store';
import { exception, information } from '@/appinsights/logging/ApplicationInsightLog';

export interface Street {
	name: string;
	nos?: string[];
}
export interface AddressBlock {
	zipCity: string;
	streets: Array<Street>;
}

export interface AddressSearch {
	houseNr: string;
	streetName: string;
	zipCode: string;
	zipName: string;
}
type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export default class BuyInsuranceSettings {
	// settings from CMS
	public initialTimeout = 500;
	public animationTimeout = 1250;
	public animationTimeoutBuy = 1500;
	public personDataLink = '/kundeservice/generel-information/vi-passer-paa-dine-persondata/';
	public defaultCard: string;
	public dataGtm = 'button-click';
	public btnStyle = 'btn-solid-secondary';
	public btnLabel = 'Ok';
	public failText =
		'Men vi har dygtige medarbejdere siddende klar ved telefonerne, til at hjælpe dig videre. Klik på "Ring 96101713", for at blive stillet direkte om.';
	public btnSecondStyle = 'btn-outline-secondary';
	public btnSecondLabel = 'Ok';
	public failTitle = '<br/>Vi kan desværre ikke give dig en pris online';
	public contactTitle = 'Kontakt os';
	public contactText: string;
	public contactCanCall = true;
	public contactCanWrite = true;
	public contactCompleteOffer = true;
	public contactDirectMail: string;
	public contactMailTo: string;
	public questionTitle = 'Hjælp til køb af forsikringer';
	public ratePopover: string = undefined;
	public rkiHeader = 'Er du registreret i RKI';
	public rkiPopover: string = undefined;
	public rkiPopupTitle: string = undefined;
	public rkiPopupContent: string = undefined;
	public damagesPopupTitle: string = undefined;
	public damagesPopupContent: string = undefined;
	public damagesOk: string[] = ['0', '1', '2'];
	public createOnlineQuote = false;
	public open = '08.00, 15.30, 1.2.3.4.5';
	public closingDays: string[] = ['12-24', '12-31'];
	public paymentBox =
		'Din betaling starter først den dag forsikringen træder i kraft.<br><br>Du har 14-dages fuld fortrydelsesret.';
	public insuranceCompanies: string[] = [];
	public insuranceCompaniesIds: string[] = [];
	public ipidText: string;
	public ipidLink: string;
	public conditionsText: string;
	public conditionsLink: string;
	public ipidText1: string;
	public ipidLink1: string;
	public conditionsText1: string;
	public conditionsLink1: string;
	public ieBrowserContent =
		'Vi understøtter desværre ikke den browser du bruger. For at få siden til at virke, anbefaler vi at du i stedet åbner den i Google Chrome, Microsoft Edge eller lignende.';
	public ieBrowserTitle = 'Ældre browser';
	public samletTilbudLink: string; //'/landingpages/bestil-samlet-tilbud/';
	public samletTilbudText: string; // = 'Brug for flere forsikringer - gå til samlet tilbud';
	public defaultCalcErrorTitle = 'Der er desværre sket en fejl';
	public defaultCalcErrorContent =
		'Der er desværre sket en uforudset fejl. Prøv evt. at indtaste oplysninger igen eller kontakt Alm. Brand';
	public yearlySavings: string = undefined;
	public existingInsuranceText = 'Har du en eksisterende forsikring, som vi skal opsige for dig?';
	public minCustomerAge = 18;
	public customerAgePopupTitle: string = undefined;
	public customerAgePopupContent: string = undefined;
	public customerAgePopupRedirect: string = undefined;
	public calculationSpinnerRunTimes = 25000;
	public calculationSpinnerInterval = 1000;
	public calculatedHeadline = 'Valgt pakke';
	public closeCalculator = false;
	public closeCalculatorPopupTitle: string;
	public closeCalculatorPopupContent: string;
	public btnLabelClose: string;
	public btnSecondLabelClose: string;
	public btnSecondLabelCloseStyle: string;
	public btnRedirectClose: boolean;
	public btnRedirectUrlClose: string;
	public btnCallMeUpEnabled: boolean;
	public recommendationTxt: string;
	public recommendationPackage: string;
	public recommendationPopover: string;
	public AllCalculationProducts: string[] = [
		'indboforsikring',
		'husforsikring',
		'fritidshusforsikring',
		'ulykkesforsikring',
		'rejseforsikring',
		'bilforsikring',
		'hundeforsikring',
		'børneulykkesforsikring',
		'børnefamilieforsikring',
	];
	public discountProducts: string[] = [
		'Indboforsikring',
		'Husforsikring',
		'Fritidshusforsikring',
		'Ulykkesforsikring',
		'Rejseforsikring',
		'Bilforsikring',
		'Motorcykelforsikring',
		'Veterankøretøjsforsikring',
		'Campingvognforsikring',
		'Hesteforsikring',
		'Hundeforsikring',
	];
	public discountPrimaryProducts: string[] = ['Indboforsikring'];
	public discountSecondaryProducts: string[] = [
		'Husforsikring',
		'Fritidshusforsikring',
		'Ulykkesforsikring',
		'Bilforsikring',
	];
	public discountTernaryProducts: string[] = [
		'Husforsikring',
		'Fritidshusforsikring',
		'Ulykkesforsikring',
		'Rejseforsikring',
		'Bilforsikring',
		'Motorcykelforsikring',
		'Veterankøretøjsforsikring',
		'Campingvognforsikring',
		'Hesteforsikring',
		'Hundeforsikring',
	];
	public discountProductNone = 'Ingen af disse forsikringer';
	public validCampaigns: string[] = []; // 'diba', 'almbrandonline' - will be set programmatically if any campaigns
	private campaignsRaw: string;
	public campaigns: Map<string, any> = new Map(); //new Map([['DIBA', { groupId: 671, blockId: 'deec8f99-9e42-45d2-b812-e7c0443d5e12', discount: 0}]]);
	private campaignSplash: string;
	private campaignSplashIcons: string;
	public plusCustomerNoDiscount: string;
	public plusCustomerNoDiscountNoCampaignDiscount: string;
	public plusCustomerDiscount: string;
	public plusCustomerPopover: string;
	public calculationInvalid: string;
	public calculationNotDone: string;
	public showBasket = true;
	public addProductsTxt = 'Tilføj forsikringer';
	public btnInlineLabel: string;
	public btnInlineStyle = 'btn-outline-secondary mt-3 mb-3';

	public useBtnInlineFail: boolean;
	public btnInlineFailLabel: string; //'Prøv igen',
	public btnInlineFailStyle: string; //'btn-outline-primary mt-3 mb-3',
	public btnInlineIcon: string;

	public selvrisikoLabel: string;
	public packageSubheader: string;
	public coverageGroups: string;
	public coverageGroupDescriptions: string;
	public coverageGroupsSubFeatures: string;
	public plusCustomerCalculation = false;
	public mockData = false;
	public suggestTextMiniBasket = true;
	public miniBasketDiscountPreTxt = '<br/><span><i>Plus</i>Kunde rabat <strong><em>';
	public miniBasketDiscountPostTxt = '</em></strong></span>';
	public suggestProductsText: string;
	public suggestProductsText1: string;
	public extendCoverages = false;
	public campaignStoreValidMillis: number = 1000 * 60 * 30; // default 30 min
	public overviewShowYearlyPrice = false;
	public tryLoadCustomer = false;
	public receiptOpenTxt: string;
	public receiptClosedTxt: string;
	public receiptOneTxt: string;
	public receiptBasketTxt: string;
	public receiptOpenMissingProduct: string;
	public receiptClosedMissingProduct: string;
	public mailConfirmationToTeam: boolean;
	public badge: string;
	public callupAddressesPopupTitle: string;
	public callupAddressesPopupContent: string;
	public queryProductNames: string[] = [];
	public queryProductValues: string[] = [];
	public addressSupplement = 1;
	public shortTitle = '';
	public shortMainTitle = 'Beregn pris';
	public wrongAddressPopuptitle = 'Adresse oplysninger stemmer ikke overens';
	public wrongAddressPopupContent =
		'Dine adresse oplysninger passer ikke overens med tidligere indtastning<br><strong>@zipTxt</strong>';
	public hasGlobalMessage = false;
	public globalMessageTxt =
		'<h2>Der kan forekomme driftforstyrrelser</h2>Da vi er ved at opdatere vores systemer, kan der forekomme udfald.<br> Hvis du oplever problemer, så prøv venligst igen lidt senere.<br><br>Vi beklager ulejligheden';
	public globalMessageDate = '15-2';
	public globalMessageFrom = '13:00';
	public globalMessageTo = '13:59';
	public globalMessageTimeout = 2000;
	public globalMessageMultipleDates = [];
	public useLocalStorage = false;
	public openForCall: string;
	public closingDaysForCall: string[] = [];
	public contactTextNoCall: string;
	public calculatorConfigId: string;
	public sendLeadMail: boolean;
	public phoneNoPopOver: string;
	public selvrisikoPopover: string;
	public useNewPolicyAPI: boolean;
	public logToAppUiTest: boolean;
	public customerAddressRequired: boolean;
	public showYearlyPrices = false;
	public regNoPopover: string;
	public accountNoPopover: string;
	public skipAccountInfo = false;

	// upSale campaign
	public campaignProductsUpSale: string[] = [];
	public multiplePkStartBtnUpSale: string;
	public mainTxtUpSale: string;
	public mainTxtBasketUpSale: string;
	public txtProductUpSale: string;
	public txtMultipleProductUpSale: string;
	public productAmountTxtUpSale: string;
	public singleProductTxtUpSale: string; // +{{existingDiscountAddOn}}
	public productTotalAmountTxtUpSale: string;
	public productTotalAmountNumberUpSale: string;
	public toggleTitleClosedUpSale: string;
	public toggleTitleOpenUpSale: string;
	public btnClassUpSale: string;
	public btnLabelUpSale: string;
	public multiplePkStartUrlUpSale: string;
	public notCalculatedUpSaleProduct: string;
	public secondarySuggestOrderUpSale: string[] = [];
	public consentTextNoCompany: string;
	public consentText: string;
	public consentLinkText: string;
	public consentDetailsText: string;

	// set on load
	public coveragesCmsText: Map<string, string>;
	public callUpAddressesBlocks: Array<AddressBlock> = [];
	public sendProposalToEmail = true;
	public sendProposalToSalesForce = false;
	public salesForceTestUri = '';

	public coverageGrouping: Map<string, Array<string>>;
	public allSubFeatures: string[];

	// public iconGlobalAssetsPath = '/globalassets/global-images/Naboo/svg/';

	public settingsBlock: HtmlBlock;

	public constructor(settingsBlock: HtmlBlock) {
		this.settingsBlock = settingsBlock;

		// when OSS is in problem answering phones, wee need to replace their phone no with customerService no
		this.mapOssPhoneNoToCustomerServiceNo();

		this.loadSettings();
		this.mapCampaigns();
		this.setCoverageGroups();
	}

	public mapOssPhoneNoToCustomerServiceNo() {
		try {
			const replacePhoneNo = this.settingsBlock.markup
				.toLowerCase()
				.includes('replaceossphonenowithcustomerservice=true');
			if (replacePhoneNo) {
				this.settingsBlock.markup = this.settingsBlock.markup.replaceAll('96101713', '96101717');
				this.settingsBlock.markup = this.settingsBlock.markup.replaceAll('96 10 17 13', '96 10 17 17');
			}
		} catch (e) {
			// too bad
		}
	}

	// get productList for discount, excluding current product
	public getDiscountProducts(basket: string[]): string[] {
		const remove = [];
		if (basket.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING)) {
			// remove indbo og ulykke
			remove.push(InsurancesProductEnum.INDBO_FORSIKRING);
			remove.push(InsurancesProductEnum.ULYKKES_FORSIKRING);
		}

		if (
			(basket.includes(InsurancesProductEnum.INDBO_FORSIKRING) &&
				basket.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) ||
			basket.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)
		) {
			remove.push(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING);
		}

		if (basket.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)) {
			remove.push(InsurancesProductEnum.ULYKKES_FORSIKRING);
		}
		const products = this.discountProducts.filter((insurance) => {
			const product = insurance.toLocaleLowerCase();
			if (remove.includes(product)) {
				return false;
			}
			if (basket.includes(product)) {
				return false;
			}
			return true;
		});
		products.push(this.discountProductNone);
		return products;
	}

	public getCoverageName(name: string) {
		if (!name) {
			return undefined;
		}
		if (!this.coveragesCmsText) {
			return name;
		}
		const n = name.toLocaleLowerCase();
		const res = this.coveragesCmsText.get(n);
		return res ? res : name;
	}
	public getCoverageDescription(name: string, defaultDescription: string) {
		if (!this.coveragesCmsText) {
			return defaultDescription;
		}
		const n = name.toLocaleLowerCase() + '_beskrivelse';
		const res = this.coveragesCmsText.get(n);
		return res ? res : defaultDescription;
	}

	/**Map campaigns */
	private mapCampaigns(): void {
		if (this.campaignsRaw) {
			const campaigns: string[] = this.campaignsRaw.toLocaleUpperCase().split('¤');
			if (campaigns.length > 0) {
				this.validCampaigns = [];
				this.campaigns = new Map();
				campaigns.map((agreement) => agreement.trim());
				campaigns.forEach((agreement_) => {
					const block = agreement_.split('|').map((elem) => elem.trim());
					const campaignId = block[0].toLocaleLowerCase(); // eks. diba
					const data = {
						groupId: parseInt(block[1]),
						blockId: block[2],
						discount: parseFloat(block[3]),
						includeDiscountPlusCustomer: block[4] === 'TRUE',
						description: block[5].toLocaleLowerCase(),
						tiaDiscount: block.length > 6 ? parseFloat(block[6]) : 0,
						productStepDiscount:
							block.length > 7 ? block[7].split(',').map((x) => parseFloat(x.trim())) : [0],
						splash: undefined,
						splashIcons: undefined,
					};
					data.productStepDiscount =
						data.productStepDiscount.length > 1 ? data.productStepDiscount : undefined;

					this.validCampaigns.push(campaignId);
					this.campaigns.set(campaignId, data); // eks. {'diba' { groupId: 671, blockId: 'deec8f99-9e42-45d2-b812-e7c0443d5e12', 1}}

					// splash txt
					if (this.campaignSplash) {
						const campaignSplash: string[] = this.campaignSplash.split('¤');
						const splashObj: Map<string, string> = new Map();
						campaignSplash.forEach((splash) => {
							if (splash.toLocaleLowerCase().includes(campaignId)) {
								const elem = splash.replace(campaignId.toLocaleUpperCase() + '|', '');
								const splashElem = elem.split('|');
								splashObj.set(splashElem[0], splashElem[1]);
							}
						});
						if (splashObj.size > 0) {
							data.splash = splashObj;
							// splash ikons
							if (this.campaignSplashIcons) {
								const campaignSplashIcons: string[] = this.campaignSplashIcons.split('¤');
								const splashIconObj: Map<string, string> = new Map();
								campaignSplashIcons.forEach((icon) => {
									if (icon.toLocaleLowerCase().includes(campaignId)) {
										const elem = icon.replace(campaignId.toLocaleUpperCase() + '|', '');
										const splashElem = elem.split('|');
										splashIconObj.set(splashElem[0], splashElem[1]);
										// splashIconObj.set(splashElem[0], '<img src="/siteassets/beregnere/Ikoner/kampagne/bilkampagne250kr.svg" style="width:150px; margin-top:-32px">');
									}
								});
								if (splashIconObj.size > 0) {
									data.splashIcons = splashIconObj;
								}
							}
						}
					}
				});
			}
		}
		// console.log('campaigns', this.campaigns);
	}

	/**
	 * load settings from CMS if present
	 */

	private loadSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');

			// const properties = Object.getOwnPropertyNames(this);
			// console.log('setting properties', properties);
			// properties.forEach((prop) => {
			// 	const property = prop.toLocaleUpperCase() + '=';
			// 	console.log(prop, typeof this[prop]);

			// 	// getTypeofProperty(this,prop)
			// });

			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('animationtimeout=') > -1) {
					const timeout = parseInt(this.getSettingsValue(setting_));
					this.animationTimeout = timeout;
				} else if (setting.indexOf('persondatalink=') > -1) {
					this.personDataLink = this.getSettingsValue(setting_);
				} else if (setting.indexOf('initialtimeout=') > -1) {
					const timeout = parseInt(this.getSettingsValue(setting_));
					this.initialTimeout = timeout;
				} else if (setting.indexOf('animationtimeoutbuy=') > -1) {
					const timeout = parseInt(this.getSettingsValue(setting_));
					this.animationTimeoutBuy = timeout;
				} else if (setting.indexOf('defaultcard=') > -1) {
					this.defaultCard = this.getSettingsValue(setting_);
				} else if (setting.indexOf('data-gtm=') > -1) {
					this.dataGtm = this.getSettingsValue(setting_);
				} else if (setting.indexOf('failtext=') > -1) {
					this.failText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnstyle=') > -1) {
					this.btnStyle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnlabel=') > -1) {
					this.btnLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnsecondstyle=') > -1) {
					this.btnSecondStyle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnsecondlabel=') > -1) {
					this.btnSecondLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('failtitle=') > -1) {
					this.failTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('contacttitle=') > -1) {
					this.contactTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('contacttext=') > -1) {
					this.contactText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('contactcancall=') > -1) {
					this.contactCanCall = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('contactcanwrite=') > -1) {
					this.contactCanWrite = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('contactcompleteoffer=') > -1) {
					this.contactCompleteOffer = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('contactdirectmail=') > -1) {
					this.contactDirectMail = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('contactmailto=') > -1) {
					this.contactMailTo = this.getSettingsValue(setting_);
				} else if (setting.indexOf('questiontitle=') > -1) {
					this.questionTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('ratepopover=') > -1) {
					this.ratePopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkiheader=') > -1) {
					this.rkiHeader = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkipopover=') > -1) {
					this.rkiPopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkipopuptitle=') > -1) {
					this.rkiPopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('rkipopupcontent=') > -1) {
					this.rkiPopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('damagespopuptitle=') > -1) {
					this.damagesPopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('damagespopupcontent=') > -1) {
					this.damagesPopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('damagesok=') > -1) {
					const damages = this.getSettingsValue(setting_);
					this.damagesOk = damages.split('¤');
				} else if (setting.indexOf('createonlinequote=') > -1) {
					const onlineQuote = JSON.parse(this.getSettingsValue(setting_));
					this.createOnlineQuote = onlineQuote;
				} else if (setting.indexOf('open=') > -1) {
					this.open = this.getSettingsValue(setting_);
				} else if (setting.indexOf('closingdays=') > -1) {
					this.closingDays = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('paymentbox=') > -1) {
					this.paymentBox = this.getSettingsValue(setting_);
				} else if (setting.indexOf('insurancecompanies=') > -1) {
					this.insuranceCompanies = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('insurancecompaniesids=') > -1) {
					this.insuranceCompaniesIds = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('ipidtext=') > -1) {
					this.ipidText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('ipidlink=') > -1) {
					this.ipidLink = this.getSettingsValue(setting_);
				} else if (setting.indexOf('conditionstext=') > -1) {
					this.conditionsText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('conditionslink=') > -1) {
					this.conditionsLink = this.getSettingsValue(setting_);
				} else if (setting.indexOf('ipidtext1=') > -1) {
					this.ipidText1 = this.getSettingsValue(setting_);
				} else if (setting.indexOf('ipidlink1=') > -1) {
					this.ipidLink1 = this.getSettingsValue(setting_);
				} else if (setting.indexOf('conditionstext1=') > -1) {
					this.conditionsText1 = this.getSettingsValue(setting_);
				} else if (setting.indexOf('conditionslink1=') > -1) {
					this.conditionsLink1 = this.getSettingsValue(setting_);
				} else if (setting.indexOf('iebrowsercontent=') > -1) {
					this.ieBrowserContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('iebrowsertitle=') > -1) {
					this.ieBrowserTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('samlettilbudlink=') > -1) {
					this.samletTilbudLink = this.getSettingsValue(setting_);
				} else if (setting.indexOf('samlettilbudtext=') > -1) {
					this.samletTilbudText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('defaultcalcerrortitle=') > -1) {
					this.defaultCalcErrorTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('defaultcalcerrorcontent=') > -1) {
					this.defaultCalcErrorContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('yearlysavings=') > -1) {
					this.yearlySavings = this.getSettingsValue(setting_);
				} else if (setting.indexOf('existinginsurancetext=') > -1) {
					this.existingInsuranceText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('mincustomerage=') > -1) {
					this.minCustomerAge = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('customeragepopuptitle=') > -1) {
					this.customerAgePopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customeragepopupcontent=') > -1) {
					this.customerAgePopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('customeragepopupredirect=') > -1) {
					this.customerAgePopupRedirect = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculationspinnerruntimes=') > -1) {
					this.calculationSpinnerRunTimes = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('calculationspinnerinterval=') > -1) {
					this.calculationSpinnerInterval = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('calculatedheadline=') > -1) {
					this.calculatedHeadline = this.getSettingsValue(setting_);
				} else if (setting.indexOf('closecalculator=') > -1) {
					this.closeCalculator = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('closecalculatorpopuptitle=') > -1) {
					this.closeCalculatorPopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('closecalculatorpopupcontent=') > -1) {
					this.closeCalculatorPopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnlabelclose=') > -1) {
					this.btnLabelClose = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnsecondlabelclose=') > -1) {
					this.btnSecondLabelClose = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnsecondlabelclosestyle=') > -1) {
					this.btnSecondLabelCloseStyle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnredirectclose=') > -1) {
					this.btnRedirectClose = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('btnredirecturlclose=') > -1) {
					this.btnRedirectUrlClose = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btncallmeupenabled=') > -1) {
					this.btnCallMeUpEnabled = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('recommendationtxt=') > -1) {
					this.recommendationTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('recommendationpackage=') > -1) {
					this.recommendationPackage = this.getSettingsValue(setting_);
				} else if (setting.indexOf('recommendationpopover=') > -1) {
					this.recommendationPopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('allcalculationproducts=') > -1) {
					this.AllCalculationProducts = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('discountproducts=') > -1) {
					this.discountProducts = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('=discountproductnone') > -1) {
					this.discountProductNone = this.getSettingsValue(setting_);
				} else if (setting.indexOf('discountprimaryproducts=') > -1) {
					this.discountPrimaryProducts = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('discountsecondaryproducts=') > -1) {
					this.discountSecondaryProducts = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('discountternaryproducts=') > -1) {
					this.discountTernaryProducts = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('campaigns=') > -1) {
					this.campaignsRaw = this.getSettingsValue(setting_);
				} else if (setting.indexOf('campaignsplash=') > -1) {
					this.campaignSplash = this.getSettingsValue(setting_);
				} else if (setting.indexOf('campaignsplashicons=') > -1) {
					this.campaignSplashIcons = this.getSettingsValue(setting_);
				} else if (setting.indexOf('pluscustomernodiscount=') > -1) {
					this.plusCustomerNoDiscount = this.getSettingsValue(setting_);
				} else if (setting.indexOf('pluscustomernodiscountnocampaigndiscount=') > -1) {
					this.plusCustomerNoDiscountNoCampaignDiscount = this.getSettingsValue(setting_);
				} else if (setting.indexOf('pluscustomerdiscount=') > -1) {
					this.plusCustomerDiscount = this.getSettingsValue(setting_);
				} else if (setting.indexOf('pluscustomerpopover=') > -1) {
					this.plusCustomerPopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculationinvalid=') > -1) {
					this.calculationInvalid = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculationnotdone=') > -1) {
					this.calculationNotDone = this.getSettingsValue(setting_);
				} else if (setting.indexOf('showbasket=') > -1) {
					this.showBasket = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('addproductstxt=') > -1) {
					this.addProductsTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btninlinelabel=') > -1) {
					this.btnInlineLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btninlinestyle=') > -1) {
					this.btnInlineStyle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btninlinefaillabel=') > -1) {
					this.btnInlineFailLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btninlinefailstyle=') > -1) {
					this.btnInlineFailStyle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btninlineicon=') > -1) {
					this.btnInlineIcon = this.getSettingsValue(setting_);
				} else if (setting.indexOf('usebtninlinefail=') > -1) {
					this.useBtnInlineFail = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('coveragegroups=') > -1) {
					this.coverageGroups = this.getSettingsValue(setting_);
				} else if (setting.indexOf('coveragegroupdescriptions=') > -1) {
					this.coverageGroupDescriptions = this.getSettingsValue(setting_);
				} else if (setting.indexOf('coveragegroupssubfeatures=') > -1) {
					this.coverageGroupsSubFeatures = this.getSettingsValue(setting_);
				} else if (setting.startsWith('pluscustomercalculation=')) {
					this.plusCustomerCalculation = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('mockdata=')) {
					if (isDevelop) {
						this.mockData = JSON.parse(this.getSettingsValue(setting_));
						if (!this.mockData && store.state.doMock) {
							information('Overriding mockdata develop to true');
							this.mockData = true;
						}
					} else {
						this.mockData = false;
					}
				} else if (setting.indexOf('selvrisikolabel=') > -1) {
					this.selvrisikoLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('packagesubheader=') > -1) {
					this.packageSubheader = this.getSettingsValue(setting_);
				} else if (setting.indexOf('suggestproductstext=') > -1) {
					this.suggestProductsText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('suggestproductstext1=') > -1) {
					this.suggestProductsText1 = this.getSettingsValue(setting_);
				} else if (setting.startsWith('suggesttextminibasket=')) {
					this.suggestTextMiniBasket = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('minibasketdiscountpretxt=') > -1) {
					this.miniBasketDiscountPreTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('minibasketdiscountposttxt=') > -1) {
					this.miniBasketDiscountPostTxt = this.getSettingsValue(setting_);
				} else if (setting.startsWith('extendcoverages=')) {
					this.extendCoverages = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('campaignstorevalidmillis=')) {
					this.campaignStoreValidMillis = parseInt(this.getSettingsValue(setting_));
				} else if (setting.startsWith('overviewshowyearlyprice=')) {
					this.overviewShowYearlyPrice = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('tryloadcustomer=')) {
					this.tryLoadCustomer = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('receiptopentxt=') > -1) {
					this.receiptOpenTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('receiptclosedtxt=') > -1) {
					this.receiptClosedTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('receiptonetxt=') > -1) {
					this.receiptOneTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('receiptbaskettxt=') > -1) {
					this.receiptBasketTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('receiptopenmissingproduct=') > -1) {
					this.receiptOpenMissingProduct = this.getSettingsValue(setting_);
				} else if (setting.indexOf('receiptclosedmissingproduct=') > -1) {
					this.receiptClosedMissingProduct = this.getSettingsValue(setting_);
				} else if (setting.startsWith('mailconfirmationtoteam=')) {
					this.mailConfirmationToTeam = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('badge=') > -1) {
					this.badge = this.getSettingsValue(setting_);
				} else if (setting.indexOf('callupaddresses=') > -1) {
					this.mapAdresses(this.getSettingsValue(setting_).toLocaleLowerCase());
				} else if (setting.indexOf('callupaddressespopuptitle=') > -1) {
					this.callupAddressesPopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('callupaddressespopupcontent=') > -1) {
					this.callupAddressesPopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('queryproductnames=') > -1) {
					this.queryProductNames = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('queryproductvalues=') > -1) {
					this.queryProductValues = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('addresssupplement=')) {
					this.addressSupplement = parseFloat(this.getSettingsValue(setting_));
				} else if (setting.indexOf('shorttitle=') > -1) {
					this.shortTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('shortmaintitle=') > -1) {
					this.shortMainTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('wrongaddresspopuptitle=') > -1) {
					this.wrongAddressPopuptitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('wrongaddresspopupcontent=') > -1) {
					this.wrongAddressPopupContent = this.getSettingsValue(setting_);
				} else if (setting.startsWith('hasglobalmessage=')) {
					this.hasGlobalMessage = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('globalmessagetxt=') > -1) {
					this.globalMessageTxt = this.getSettingsValue(setting_);
				} else if (setting.indexOf('globalmessagedate=') > -1) {
					this.globalMessageDate = this.getSettingsValue(setting_);
				} else if (setting.indexOf('globalmessagefrom=') > -1) {
					this.globalMessageFrom = this.getSettingsValue(setting_);
				} else if (setting.indexOf('globalmessageto=') > -1) {
					this.globalMessageTo = this.getSettingsValue(setting_);
				} else if (setting.indexOf('globalmessagetimeout=') > -1) {
					this.globalMessageTimeout = parseInt(this.getSettingsValue(setting_));
				} else if (setting.startsWith('uselocalstorage=')) {
					this.useLocalStorage = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('globalmessagemultipledates=') > -1) {
					this.globalMessageMultipleDates = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('closingdaysforcall=') > -1) {
					this.closingDaysForCall = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('openforcall=') > -1) {
					this.openForCall = this.getSettingsValue(setting_);
				} else if (setting.indexOf('contacttextnocall=') > -1) {
					this.contactTextNoCall = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculatorconfigid=') > -1) {
					this.calculatorConfigId = this.getSettingsValue(setting_);
				} else if (setting.startsWith('sendleadmail=')) {
					this.sendLeadMail = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('phonenopopover=') > -1) {
					this.phoneNoPopOver = this.getSettingsValue(setting_);
				} else if (setting.indexOf('selvrisikopopover=') > -1) {
					this.selvrisikoPopover = this.getSettingsValue(setting_);
				} else if (setting.startsWith('usenewpolicyapi=')) {
					this.useNewPolicyAPI = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('logtoappuitest=')) {
					this.logToAppUiTest = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('sendproposaltoemail=')) {
					this.sendProposalToEmail = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('sendproposaltosalesforce=')) {
					this.sendProposalToSalesForce = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('customeraddressrequired=')) {
					this.customerAddressRequired = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('salesforcetesturi=') > -1) {
					this.salesForceTestUri = this.getSettingsValue(setting_);
				} else if (setting.startsWith('showyearlyprices=')) {
					this.showYearlyPrices = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('regnopopover=') > -1) {
					this.regNoPopover = this.getSettingsValue(setting_);
				} else if (setting.indexOf('accountnopopover=') > -1) {
					this.accountNoPopover = this.getSettingsValue(setting_);
				} else if (setting.startsWith('skipaccountinfo=')) {
					this.skipAccountInfo = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('campaignproductsupsale=') > -1) {
					this.campaignProductsUpSale = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('secondarysuggestorderupsale=') > -1) {
					this.secondarySuggestOrderUpSale = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('multiplepkstartbtnupsale=') > -1) {
					this.multiplePkStartBtnUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('maintxtupsale=') > -1) {
					this.mainTxtUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('maintxtbasketupsale=') > -1) {
					this.mainTxtBasketUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('txtproductupsale=') > -1) {
					this.txtProductUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('txtmultipleproductupsale=') > -1) {
					this.txtMultipleProductUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('productamounttxtupsale=') > -1) {
					this.productAmountTxtUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('producttotalamounttxtupsale=') > -1) {
					this.productTotalAmountTxtUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('producttotalamountnumberupsale=') > -1) {
					this.productTotalAmountNumberUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('toggletitleclosedupsale=') > -1) {
					this.toggleTitleClosedUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('toggletitleopenupsale=') > -1) {
					this.toggleTitleOpenUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnclassupsale=') > -1) {
					this.btnClassUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('btnlabelupsale=') > -1) {
					this.btnLabelUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('multiplepkstarturlupsale=') > -1) {
					this.multiplePkStartUrlUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('notcalculatedupsaleproduct=') > -1) {
					this.notCalculatedUpSaleProduct = this.getSettingsValue(setting_);
				} else if (setting.indexOf('singleproducttxtupsale=') > -1) {
					this.singleProductTxtUpSale = this.getSettingsValue(setting_);
				} else if (setting.indexOf('consenttextnocompany=') > -1) {
					this.consentTextNoCompany = this.getSettingsValue(setting_);
				} else if (setting.indexOf('consenttext=') > -1) {
					this.consentText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('consentlinktext=') > -1) {
					this.consentLinkText = this.getSettingsValue(setting_);
				} else if (setting.indexOf('consentdetailstext=') > -1) {
					this.consentDetailsText = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			exception(e);
		}
	}
	public getSettingsValue(setting: string): any {
		return setting.substring(setting.indexOf('=') + 1, setting.length);
	}

	/**Map addresses to object searchable tree */
	public mapAdresses(callUpAdressesRaw: string): void {
		if (callUpAdressesRaw) {
			const zips: string[] = callUpAdressesRaw.split('¤');
			zips.map((zip) => zip.trim());

			zips.forEach((addressBlock) => {
				let block = addressBlock.split('_').map((elem) => elem.trim());
				const zipCity = block[0];

				// remove zipCity
				block = block.filter((element) => element !== zipCity);

				const streets: Array<Street> = [];

				block.forEach((element) => {
					const street = element.split(':').map((elem) => elem.trim());
					const name = street[0];
					// remove name
					const nos = street[1];

					streets.push({
						name,
						nos: nos ? nos.split(',').map((elem) => elem.trim()) : undefined,
					});
				});
				this.callUpAddressesBlocks.push({
					zipCity,
					streets,
				});
			});
		}
	}

	private setCoverageGroups() {
		if (this.coverageGroups && this.coverageGroupsSubFeatures) {
			const groups: string[] = this.coverageGroups.split('¤');
			const subGroup: string[] = this.coverageGroupsSubFeatures.split('_');
			this.coverageGrouping = new Map();
			this.allSubFeatures = [];
			groups.forEach((group, inx) => {
				const subFeatures = subGroup[inx].split('¤');
				this.coverageGrouping.set(group, subFeatures);
				this.allSubFeatures = this.allSubFeatures.concat(subFeatures);
			});
		}
	}

	public mapCoverageGroups(coverages: Array<any>): void {
		if (this.coverageGroups && this.coverageGroupsSubFeatures) {
			const groupedCovs: Map<string, string> = new Map();
			this.coverageGrouping.forEach((features, name) => {
				groupedCovs.set(name, undefined);
			});
			let removeCoverages: Array<number> = [];
			if (this.coverageGrouping) {
				coverages.forEach((cov, inx) => {
					const name = cov.name ? cov.name : cov.title;
					if (this.allSubFeatures.includes(name)) {
						this.coverageGrouping.forEach((features, group) => {
							if (features.includes(name)) {
								let subFeatures = groupedCovs.get(group);
								if (!subFeatures) {
									subFeatures = '<ul class="subfeature_description">';
								}
								subFeatures += `<li><b>${name}</b><br>${cov.description}</li>`;
								groupedCovs.set(group, subFeatures);
								removeCoverages.push(inx);
							}
						});
					}
				});
			}
			removeCoverages = removeCoverages.reverse();
			removeCoverages.forEach((remove) => {
				coverages.splice(remove, 1);
			});

			let inx = 0;
			const groupDesc = this.coverageGroupDescriptions.split('¤');
			groupedCovs.forEach((description, name) => {
				if (description) {
					const cov = new Coverage(name, name, name, groupDesc[inx] + description + '</ul>');
					coverages.unshift(cov);
				}
				inx++;
			});
		}
	}
}
