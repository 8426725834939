import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { AccidentChildrenSettings } from './AccidentChildrenSettings';
import { AccidentChildrenModel, STEPS } from './AccidentChildrenModel';
import AccidentChildrenCalculator from './AccidentChildrenCalculator';
import ChoosePackageStepComponent from '../commonSteps/ChoosePackageStepComponent.vue';
import ContactInformationStepComponent from '../commonSteps/ContactInformationStepComponent.vue';
import OverviewStepComponent from '../commonSteps/OverviewStepComponent.vue';
import AdditionalInfoStepFamilyChildComponent from '../commonSteps/AdditionalInfoStepFamilyChildComponent.vue';
import PersonInfoStepComponent from './steps/PersonInfoStepComponent.vue';
import FamilyInfoStepComponent from './steps/FamilyInfoStepComponent.vue';
import PaymentStepComponent from '../commonSteps/PaymentStepComponent.vue';
import ReceiptStepComponent from '../commonSteps/ReceiptStepComponent.vue';
import CampaignStepComponent from '../commonSteps/CampaignStepComponent.vue';
import { CardBlock, HtmlBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import { PropType } from 'vue';
import store from '@/store/store';
import { CALCULATOR_LOADED, INIT_CALCULATOR, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { gotoAddInsurances, nextStep, setFirstCard } from '../services/NavigationService';
import { resetSelectedCalculation, subscribeBasketChange } from '../services/CalculationService';
import {
	getShowReceipt,
	isValidAdditionalInfo,
	isValidAlmbrandProducts,
	isValidContactInfo,
	isValidPackage,
	isValidPaymentInfo,
	Validator,
} from '../services/ValidationService';
import { getCardTitle, getSubtitle, setDetailsYearlyPrice, setHighLights } from '../services/ProductService';
import { initComponent, STEP } from '../services/UiStepService';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import MapOrderDescription from './MapOrderDescription';
import { CalculatorUiComponent } from '../BuyInsuranceComponent';

@Options({
	name: 'BuyInsuranceAccidentChildrenComponent',
	props: {
		contentBlocks: Object as PropType<Array<CardBlock>>,
		cardReceipt: Object as PropType<CardBlock>,
		settingsBlock: Object as PropType<HtmlBlock>,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
		contentUrl: String, // Used by service to handle browser history
		headerBadgeTxt: String,
		hasBadgeText: Boolean,
		calculatorInfoBlock: Object as PropType<Array<HtmlBlock>>,
	},
	components: {
		UsabillaInPageComponent,
		FamilyInfoStepComponent,
		PersonInfoStepComponent,
		ChoosePackageStepComponent,
		OverviewStepComponent,
		AdditionalInfoStepFamilyChildComponent,
		ContactInformationStepComponent,
		PaymentStepComponent,
		ReceiptStepComponent,
		CampaignStepComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class BuyAccidentChildren extends Vue implements CalculatorUiComponent {
	contentBlocks: Array<CardBlock>;
	cardReceipt: CardBlock;
	settingsBlock: HtmlBlock;
	usabillaBlock: UsabillaInPageBlock;
	contentUrl: string; // Used by service to handle browser history
	headerBadgeTxt: string;
	hasBadgeText: boolean;
	calculatorInfoBlock: HtmlBlock;

	// list and order of steps
	public steps: Array<string> = STEPS;

	public model!: AccidentChildrenModel; // model from store
	public cms!: AccidentChildrenSettings; // setting from store
	public calculator: AccidentChildrenCalculator = null;
	public componentInit = false;
	public checkout = false;
	private readonly fieldValidator = new FieldValidator('under18');
	private mapOrderDescription: MapOrderDescription;
	private unsubScribeBasketChange: Function;

	public async created() {
		if (!store.getters.getActiveCalculator) {
			// this is first load - update store
			const cms = new AccidentChildrenSettings(this.settingsBlock);
			const model: AccidentChildrenModel = new AccidentChildrenModel();
			if (cms.mockData) {
				AccidentChildrenModel.mock(model);
			}
			await store.dispatch(INIT_CALCULATOR, {
				cms,
				model,
			});
		} else {
			await store.dispatch(CALCULATOR_LOADED);
		}

		if (!(await initComponent(this))) {
			return;
		}

		this.calculator = new AccidentChildrenCalculator(this);

		setFirstCard(this);
	}

	public mounted(): void {
		this.unsubScribeBasketChange = subscribeBasketChange(this);
	}

	beforeUnmount() {
		if (this.unsubScribeBasketChange) {
			this.unsubScribeBasketChange();
		}
	}

	// called by service
	public addResetSubscription() {
		const unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === RESET_CALCULATORS) {
				setTimeout(() => {
					this.checkout = true;
					store.dispatch(INIT_CALCULATOR, {
						cms: this.cms,
						model: new AccidentChildrenModel(),
					});
					unsubscribe();
				}, 2000);
			}
		});
	}

	public get showValidNowOption() {
		return true;
	}

	public async gotoCard(cardName: string): Promise<boolean> {
		if (cardName === STEP.PERSON_INFO || cardName === STEP.CHILDREN_INFO || cardName === STEP.PACKAGE) {
			resetSelectedCalculation(this.model);
		}

		if (cardName === STEP.PACKAGE) {
			// altid kun en risk (dummy)
			this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;
			this.calculator.setupExcessList();
		}

		if (cardName === STEP.PAYMENT) {
			// force user to choose yearly/monthly payment to ensure progressive steps
			this.model.choosePackage.monthYear = undefined;
			this.model.payment.userSelected = false;
		}

		this.model.currentCardName = cardName;

		if (cardName === STEP.ORDER) {
			store.state.showSpinner = true;
			if (!this.isValid(this.model.currentCardName)) {
				store.state.showSpinner = false;
				return false;
			}
			this.mapOrderDescription = new MapOrderDescription(this);
			await this.mapOrderDescription.orderByEmail();
			return false;
		}
		return true;
	}

	/**
	 * when nextbtn is clicked - check for valid
	 * @param cardName
	 */
	public nextStep(cardName: string, addToHistory = true) {
		nextStep(cardName, addToHistory, this);
	}

	public getSubtitle(cardName: string) {
		return getSubtitle(cardName, this.model);
	}

	public isActiveCard(cardName: string): boolean {
		return cardName === this.model.currentCardName;
	}

	public isValid(cardName): boolean {
		switch (cardName) {
			case STEP.CHILDREN_INFO:
				const ok = this.isChildrenFilled();
				if (ok) {
					if (this.model.familyInfo.personCountId === 0 && this.model.familyInfo.age0 !== undefined) {
						this.model.familyInfo.subtitle =
							this.model.familyInfo.name0 + ' ' + this.model.familyInfo.age0 + ' år';
					} else if (this.model.familyInfo.personCountId > 0 && this.model.familyInfo.personCount) {
						this.model.familyInfo.subtitle = this.model.familyInfo.personCount;
					} else {
						this.model.familyInfo.subtitle = '';
					}
				}
				if (!ok) {
					this.model.familyInfo.subtitle = '';
				}
				return ok;
			case STEP.PERSON_INFO:
				const okPerson =
					this.model.personInfo.zipCode &&
					this.model.personInfo.zipName &&
					isValidAlmbrandProducts(this.model);
				if (okPerson) {
					this.model.personInfo.subtitle = `${this.model.personInfo.zipCode} ${this.model.personInfo.zipName}`;
				}
				return okPerson;

			case STEP.PACKAGE:
				return isValidPackage(this);
			case STEP.OVERVIEW:
				return isValidPackage(this);
			case STEP.CONTACT_INFORMATION:
				let ok1 = isValidContactInfo(this.model, this.cms);
				if (!ok1) {
					return false;
				}
				for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
					const cpr = 'cpr' + i;
					if (
						this.model.familyInfo[cpr] === undefined ||
						Validator.getAgeUnderEighteen(this.model.familyInfo[cpr]) < 0
					) {
						ok1 = false;
						i = this.model.familyInfo.personCountId;
					}
				}
				return ok1;
			case STEP.ADDITIONAL_INFO:
				return isValidAdditionalInfo(true, this.model, this.cms);
			case STEP.PAYMENT:
				return isValidPaymentInfo(this.model, this.cms);
			default:
				return true;
		}
	}

	private isChildrenFilled(): boolean {
		let ok = this.model.familyInfo.personCount !== 0;
		if (ok) {
			for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
				const ageInx = 'age' + i;
				ok = this.fieldValidator.isValid(this.model.familyInfo[ageInx]);
				if (!ok) {
					break;
				}
				if (this.cms.showWorkForMinors && this.model.familyInfo[ageInx] >= 15) {
					if (!this.model.familyInfo['hasWork' + i]) {
						ok = false;
						break;
					}
					if (this.model.familyInfo['hasWork' + 1] === 'ja') {
						if (!this.model.familyInfo['workId' + 1]) {
							ok = false;
							break;
						}
					}
				}
			}
		}
		return ok;
	}
	// called by service
	public setOverviewData() {
		setHighLights(this.model);

		this.model.overview.details = [];
		for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
			const age = this.model.familyInfo['age' + i] + ' år';
			const name = this.model.familyInfo['name' + i];
			this.model.overview.details.push(name + ' ' + age);
		}
		this.model.overview.details.push(this.model.personInfo.zipCode + ', ' + this.model.personInfo.zipName);
		setDetailsYearlyPrice(this.model, this.cms);
	}

	/** Called by service */
	public buildDescriptionForEmail(desc: string) {
		return this.mapOrderDescription.buildDescriptionForEmail(desc);
	}

	public gotoAddInsurances(step: string) {
		gotoAddInsurances(step, this);
	}

	public get showReceipt() {
		return getShowReceipt(this);
	}

	public getCardTitle(card): string {
		return getCardTitle(card, this.model, this.cms);
	}
}
